/* eslint-disable prettier/prettier */
import {
  GET_ACADEMY_DATA,
  GET_ANALYTICS_DATA,
  GET_CRM_DATA,
  GET_CRYPTO_DATA,
  GET_ECOMMERCE_DATA,
  GET_HEALTH_CARE_DATA,
  GET_METRICS_DATA,
  GET_WIDGETS_DATA,
  GET_TEACHER_OWN_COURSES,
  SET_COURSE_DATA,
  GET_TEACHER_ANALYTICS,
  SET_DOCUMENT_CONTENT,
  SET_UNIQUE_ID,
  SET_CURRENT_COURSE,
  GET_ALL_CATEGORIES,
  SET_SUBTITLE_FILE,
  SET_ASSIGNMENT_DATA,
  SET_SPECIFIC_REVIEW,
  SET_MESSAGE_USER,
  SET_QUIZ_DETAILS,
  SET_ASSIGNMENT_DETAIL,
  SET_BONUS_DETAIL,
  SET_COURSE_CONTENT,
  SELECTED_CHAT,
} from '@crema/types/actions/Dashboard.action';

import { AcademyType } from '@crema/types/models/dashboards/Academy';
import { AnalyticsType } from '@crema/types/models/dashboards/Analytics';
import { CRMType } from '@crema/types/models/dashboards/CRM';
import { CryptoPropsType } from '@crema/types/models/dashboards/Crypto';
import { EcommerceType } from '@crema/types/models/dashboards/Ecommerce';
import { HealthCareType } from '@crema/types/models/dashboards/HealthCare';
import { MetricsType } from '@crema/types/models/dashboards/Metrics';
import { WidgetsType } from '@crema/types/models/dashboards/Widgets';
import { createAction } from '@reduxjs/toolkit';

export const GetAnalyticsAction =
  createAction<AnalyticsType>(GET_ANALYTICS_DATA);

export const GetAcademyAction = createAction<AcademyType>(GET_ACADEMY_DATA);

export const GetEcomAction = createAction<EcommerceType>(GET_ECOMMERCE_DATA);

export const GetCrmAction = createAction<CRMType>(GET_CRM_DATA);

export const GetCryptoAction = createAction<CryptoPropsType>(GET_CRYPTO_DATA);

export const GetHealthcareAction =
  createAction<HealthCareType>(GET_HEALTH_CARE_DATA);

export const GetMetricsAction = createAction<MetricsType>(GET_METRICS_DATA);

export const GetWidgetsAction = createAction<WidgetsType>(GET_WIDGETS_DATA);

export const GetTeacherOwnCourses = createAction<any>(GET_TEACHER_OWN_COURSES);

export const CourseData = createAction<any>(SET_COURSE_DATA);

export const TeacherAnalytics = createAction<any>(GET_TEACHER_ANALYTICS);

export const documentContent = createAction<any>(SET_DOCUMENT_CONTENT);

export const UniqueID = createAction<any>(SET_UNIQUE_ID);

export const SpecificCourseData = createAction<any>(SET_CURRENT_COURSE);

export const GetAllCategories = createAction<any>(GET_ALL_CATEGORIES);

export const GetSubtitleFile = createAction<any>(SET_SUBTITLE_FILE);

export const GetAssignmentData = createAction<any>(SET_ASSIGNMENT_DATA);

export const SetSpecificReview = createAction<any>(SET_SPECIFIC_REVIEW);

export const SetMessageUser = createAction<any>(SET_MESSAGE_USER);

export const SetQuizDetails = createAction<any>(SET_QUIZ_DETAILS);

export const SetAssignmentDetails = createAction<any>(SET_ASSIGNMENT_DETAIL);

export const SetBonusDetails = createAction<any>(SET_BONUS_DETAIL);

export const SetCourseContent = createAction<any>(SET_COURSE_CONTENT);

export const SelectedChatUser = createAction<any>(SELECTED_CHAT);