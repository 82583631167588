import AppLoader from '@crema/components/AppLoader';
import React, {Suspense} from 'react';
const Signin = React.lazy(() => import('../../../modules/auth/Signin'));
const Signup = React.lazy(() => import('../../../modules/auth/Signup'));
const ForgotPassword = React.lazy(
  () => import('../../../modules/auth/ForgetPassword'),
);
const ResetPasswordAwsCognito = React.lazy(
  () => import('../../../modules/auth/ForgetPassword/ResetPasswordAwsCognito'),
);
const TermsAndConditions = React.lazy(
  () => import('../../../modules/auth/TermsAndCondtions'),
);

export const authRouteConfig = [
  {
    path: '/signin',
    element: (
      <Suspense fallback={<AppLoader />}>
        <Signin />
      </Suspense>
    ),
  },
  {
    path: '/signup',
    element: (
      <Suspense fallback={<AppLoader />}>
        <Signup />
      </Suspense>
    ),
  },
  {
    path: '/forget-password',
    element: (
      <Suspense fallback={<AppLoader />}>
        <ForgotPassword />
      </Suspense>
    ),
  },
  {
    path: '/reset-password',
    element: (
      <Suspense fallback={<AppLoader />}>
        <ResetPasswordAwsCognito />
      </Suspense>
    ),
  },
  {
    path: '/termsandconditions',
    element: (
      <Suspense fallback={<AppLoader />}>
        <TermsAndConditions />
      </Suspense>
    ),
  },
];
