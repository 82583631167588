import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {AuthUserType} from '@crema/types/models/AuthUser';
import jwtAxios, {setAuthToken} from './index';
import {useInfoViewActionsContext} from '@crema/context/AppContextProvider/InfoViewContextProvider';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from 'toolkit/hooks';
import {
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
} from '@crema/types/actions/Common.action';
import {
  GET_ACADEMY_DATA,
  GET_ALL_CATEGORIES,
  GET_CRM_DATA,
  GET_CRYPTO_DATA,
  GET_ECOMMERCE_DATA,
  GET_HEALTH_CARE_DATA,
  GET_METRICS_DATA,
  GET_TEACHER_ANALYTICS,
  GET_TEACHER_OWN_COURSES,
  GET_WIDGETS_DATA,
  SELECTED_CHAT,
  SET_ASSIGNMENT_DATA,
  SET_ASSIGNMENT_DETAIL,
  SET_BONUS_DETAIL,
  SET_COURSE_CONTENT,
  SET_COURSE_DATA,
  SET_CURRENT_COURSE,
  SET_DOCUMENT_CONTENT,
  SET_MESSAGE_USER,
  SET_QUIZ_DETAILS,
  SET_SPECIFIC_REVIEW,
  SET_SUBTITLE_FILE,
  SET_UNIQUE_ID,
} from '@crema/types/actions/Dashboard.action';
interface JWTAuthContextProps {
  user: AuthUserType | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const infoViewActionsContext = useInfoViewActionsContext();

  useEffect(() => {
    const getAuthUser = async () => {
      const token = localStorage.getItem('JWTnmo');

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      try {
        const {data} = await jwtAxios.get('/user/getMe');
        setJWTAuthData({
          user: data.responseData,
          isAuthenticated: true,
          isLoading: false,
        });
      } catch (error) {
        localStorage.removeItem('JWTnmo');
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
      }
    };

    getAuthUser();
  }, []);

  const signInUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    infoViewActionsContext.fetchStart();
    try {
      const {data} = await jwtAxios.post('/auth/teacher/login', {
        email,
        password,
      });
      localStorage.setItem('JWTnmo', data.responseData.token);
      setAuthToken(data?.responseData?.token);
      const res = await jwtAxios.get('/user/getMe');
      setJWTAuthData({
        user: res?.data?.responseData,
        isAuthenticated: true,
        isLoading: false,
      });
      infoViewActionsContext.fetchSuccess();
      dispatch({type: SHOW_MESSAGE, payload: 'Successfully Login'});
    } catch (error) {
      localStorage.removeItem('JWTnmo');
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError(
        error?.response?.data?.msg || 'Something went wrong',
      );
      dispatch({
        type: SHOW_ERROR_MESSAGE,
        payload: error.response.data.message,
      });
    }
  };

  const signUpUser = async ({
    name,
    email,
    password,
    confirmPassword,
  }: {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    infoViewActionsContext.fetchStart();
    try {
      const {data} = await jwtAxios.post('auth/teacher/signup', {
        name,
        email,
        password,
        confirmPassword,
      });
      // localStorage.setItem('JWTnmo', data.token);
      // setAuthToken(data.token);
      // const res = await jwtAxios.get('/auth');
      // setJWTAuthData({
      //   user: res.data,
      //   isAuthenticated: true,
      //   isLoading: false,
      // });
      infoViewActionsContext.fetchSuccess();
      dispatch({
        type: SHOW_MESSAGE,
        payload: 'You are not verified, wait till admin verify you',
      });
      setTimeout(() => {
        navigate('/signin');
        dispatch({
          type: SHOW_MESSAGE,
          payload: null,
        });
      }, 1500);
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError('Something went wrong');
      dispatch({
        type: SHOW_ERROR_MESSAGE,
        payload: error.response.data.message,
      });
    }
  };

  const logout = async () => {
    localStorage.removeItem('JWTnmo');
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    localStorage.clear();
    sessionStorage.clear();
    dispatch({type: GET_TEACHER_OWN_COURSES, payload: null});
    dispatch({type: SHOW_MESSAGE, payload: 'Logged Out'});
    dispatch({type: GET_ACADEMY_DATA, payload: null});
    dispatch({type: GET_ECOMMERCE_DATA, payload: null});
    dispatch({type: GET_CRM_DATA, payload: null});
    dispatch({type: GET_CRYPTO_DATA, payload: null});
    dispatch({type: GET_HEALTH_CARE_DATA, payload: null});
    dispatch({type: GET_METRICS_DATA, payload: null});
    dispatch({type: GET_WIDGETS_DATA, payload: null});
    dispatch({type: SET_COURSE_DATA, payload: null});
    dispatch({type: GET_TEACHER_ANALYTICS, payload: null});
    dispatch({type: SET_DOCUMENT_CONTENT, payload: null});
    dispatch({type: SET_UNIQUE_ID, payload: null});
    dispatch({type: SET_CURRENT_COURSE, payload: null});
    dispatch({type: GET_ALL_CATEGORIES, payload: []});
    dispatch({type: SET_SUBTITLE_FILE, payload: {}});
    dispatch({type: SET_ASSIGNMENT_DATA, payload: null});
    dispatch({type: SET_SPECIFIC_REVIEW, payload: null});
    dispatch({type: SET_MESSAGE_USER, payload: null});
    dispatch({type: SET_QUIZ_DETAILS, payload: null});
    dispatch({type: SET_ASSIGNMENT_DETAIL, payload: null});
    dispatch({type: SET_BONUS_DETAIL, payload: null});
    dispatch({type: SET_COURSE_CONTENT, payload: null});
    dispatch({type: SELECTED_CHAT, payload: null});
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
