/* eslint-disable prettier/prettier */
import {
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  TOGGLE_APP_DRAWER,
  SHOW_DELETE_MODAL,
  ADD_MEDIA_PROGRESS,
  ADD_DOCUMENT_PROGRESS,
  SHOW_MODAL,
  SET_UPDATING,
} from '@crema/types/actions/Common.action';
import { createReducer } from '@reduxjs/toolkit';
import { CommonType } from '@crema/types/models/Common';
import { FetchErrorAction, ShowMsgAction, ShowErrorMessage } from './ActionTypes/Common';

const INIT_STATE: CommonType = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  message: '',
  showDeleteModal: false,
  mediaProgress: 0,
  documentProgress: 0,
  showModal: false,
  updating: false,
};

const commonReducer = createReducer(INIT_STATE, (builder) => {
  builder
    .addCase(FETCH_START, (state) => {
      state.error = '';
      state.message = '';
      state.loading = true;
    })
    .addCase(FETCH_SUCCESS, (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
    })
    .addCase(ShowMsgAction, (state, action) => {
      state.error = '';
      state.message = action.payload;
      state.loading = false;
    })
    .addCase(ShowErrorMessage, (state, action) => {
      state.error = action.payload;
      state.message = '';
      state.loading = false;
    })
    .addCase(FetchErrorAction, (state, action) => {
      state.error = action.payload;
      state.message = '';
      state.loading = false;
    })
    .addCase(HIDE_MESSAGE, (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
    })
    .addCase(TOGGLE_APP_DRAWER, (state) => {
      state.isAppDrawerOpen = !state.isAppDrawerOpen;
    }).
    addCase(SHOW_DELETE_MODAL, (state: any, action: any) => {
      state.showDeleteModal = action.payload;
    })
    .addCase(ADD_MEDIA_PROGRESS, (state: any, action: any) => {
      state.mediaProgress = action.payload;
    })
    .addCase(SHOW_MODAL, (state: any, action: any) => {
      state.showModal = action.payload;
    })
    .addCase(SET_UPDATING, (state: any, action: any) => {
      state.updating = action.payload;
    })
    .addCase(ADD_DOCUMENT_PROGRESS, (state: any, action: any) => {
      state.documentProgress = action.payload;
    });
});

export default commonReducer;
