/* eslint-disable prettier/prettier */
import { MetricsType } from '../models/dashboards/Metrics';
import { AnalyticsType } from '../models/dashboards/Analytics';
import { CRMType } from '../models/dashboards/CRM';
import { CryptoPropsType } from '../models/dashboards/Crypto';
import { WidgetsType } from '../models/dashboards/Widgets';
import { AcademyType } from '../models/dashboards/Academy';
import { HealthCareType } from '../models/dashboards/HealthCare';
import { EcommerceType } from '../models/dashboards/Ecommerce';
import { any } from 'prop-types';

export const GET_ACADEMY_DATA = 'GET_ACADEMY_DATA';
export const GET_ECOMMERCE_DATA = 'GET_ECOMMERCE_DATA';
export const GET_HEALTH_CARE_DATA = 'GET_HEALTH_CARE_DATA';
export const GET_ANALYTICS_DATA = 'GET_ANALYTICS_DATA';
export const GET_CRM_DATA = 'GET_CRM_DATA';
export const GET_CRYPTO_DATA = 'GET_CRYPTO_DATA';
export const GET_METRICS_DATA = 'GET_METRICS_DATA';
export const GET_WIDGETS_DATA = 'GET_WIDGETS_DATA';
export const GET_COURSES_DATA = 'GET_COURSES_DATA';
export const GET_TEACHER_OWN_COURSES = 'GET_TEACHER_OWN_COURSES';
export const SET_COURSE_DATA = 'SET_COURSE_DATA';
export const GET_TEACHER_ANALYTICS = 'GET_TEACHER_ANALYTICS';
export const SET_DOCUMENT_CONTENT = 'SET_DOCUMENT_CONTENT';
export const SET_UNIQUE_ID = 'SET_UNIQUE_ID';
export const SET_CURRENT_COURSE = 'SET_CURRENT_COURSE';
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const SET_SUBTITLE_FILE = 'SET_SUBTITLE_FILE';
export const SET_ASSIGNMENT_DATA = 'SET_ASSIGNMENT_DATA';
export const SET_SPECIFIC_REVIEW = 'SET_SPECIFIC_REVIEW';
export const SET_MESSAGE_USER = 'SET_MESSAGE_USER';
export const SET_QUIZ_DETAILS = 'SET_QUIZ_DETAILS';
export const SET_ASSIGNMENT_DETAIL = 'SET_ASSIGNMENT_DETAIL';
export const SET_BONUS_DETAIL = 'SET_BONUS_DETAIL';
export const SET_COURSE_CONTENT = 'SET_COURSE_CONTENT';
export const SELECTED_CHAT = 'SELECTED_CHAT';

export type GetAnalyticsAction = {
  type: typeof GET_ANALYTICS_DATA;
  payload: AnalyticsType;
};

export type GetECommerceAction = {
  type: typeof GET_ECOMMERCE_DATA;
  payload: EcommerceType;
};

export type GetHeathCareAction = {
  type: typeof GET_HEALTH_CARE_DATA;
  payload: HealthCareType;
};

export type GetAcademyAction = {
  type: typeof GET_ACADEMY_DATA;
  payload: AcademyType;
};

export type GetCRMAction = {
  type: typeof GET_CRM_DATA;
  payload: CRMType;
};

export type GetCryptosAction = {
  type: typeof GET_CRYPTO_DATA;
  payload: CryptoPropsType;
};

export type GetMetricsAction = {
  type: typeof GET_METRICS_DATA;
  payload: MetricsType;
};

export type GetWidgetsAction = {
  type: typeof GET_WIDGETS_DATA;
  payload: WidgetsType;
};

export type GetCoursesData = {
  type: typeof GET_COURSES_DATA;
  payload: any;
};

export type GetTeacherCourses = {
  type: typeof GET_TEACHER_OWN_COURSES;
  payload: any;
};

export type CourseData = {
  type: typeof SET_COURSE_DATA;
  payload: any;
}

export type GetTeacherAnalytics = {
  type: typeof GET_TEACHER_ANALYTICS;
  payload: any;
}

export type getDocumentContent = {
  type: typeof SET_DOCUMENT_CONTENT;
  payload: any;
}

export type setUniqueId = {
  type: typeof SET_UNIQUE_ID;
  payload: string;
};

export type setCurrentCourse = {
  type: typeof SET_CURRENT_COURSE;
  payload: any;
};

export type getAllCategories = {
  type: typeof GET_ALL_CATEGORIES;
  payload: any;
};

export type getSubtitleFile = {
  type: typeof SET_SUBTITLE_FILE;
  payload: any;
};

export type getAssignmentData = {
  type: typeof SET_ASSIGNMENT_DATA;
  payload: any;
};

export type setSpecificiReview = {
  type: typeof SET_SPECIFIC_REVIEW;
  payload: any;
};

export type setMessageUser = {
  type: typeof SET_MESSAGE_USER;
  payload: any;
};

export type setQuizDetails = {
  type: typeof SET_QUIZ_DETAILS;
  payload: any;
};

export type setAssignmentDetails = {
  type: typeof SET_ASSIGNMENT_DETAIL;
  payload: any;
};

export type setBonusDetails = {
  type: typeof SET_BONUS_DETAIL;
  payload: any;
};

export type setCourseContent = {
  type: typeof SET_COURSE_CONTENT;
  payload: any;
};

export type setSelectedChatUser = {
  type: typeof SELECTED_CHAT;
  payload: any;
};

export type DashboardActionTypes =
  | GetECommerceAction
  | GetHeathCareAction
  | GetAcademyAction
  | GetAnalyticsAction
  | GetCRMAction
  | GetCryptosAction
  | GetMetricsAction
  | GetWidgetsAction
  | GetCoursesData
  | GetTeacherCourses
  | CourseData
  | GetTeacherAnalytics
  | getDocumentContent
  | setUniqueId
  | setCurrentCourse
  | getAllCategories
  | getSubtitleFile
  | getAssignmentData
  | setSpecificiReview
  | setMessageUser
  | setQuizDetails
  | setAssignmentDetails
  | setBonusDetails
  | setCourseContent
  | setSelectedChatUser;
