/* eslint-disable prettier/prettier */
import { authRole } from '@crema/constants/AppConst';

export const getUserFromAuth0 = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.User,
    };
  return user;
};

export const getUserFromFirebase = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : 'Crema User',
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : '/assets/images/avatar/A11.jpg',
      role: authRole.User,
    };
  return user;
};
export const getUserFromAWS = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : 'Crema User',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.User,
    };
  return user;
};

export const getUserFromJwtAuth = (user: any) => {
  // console.log('getUserFromJwtAuth ==>', user);
  if (user)
    return {
      uid: user._id,
      displayName: user.name,
      email: user.email,
      photoURL: user.profilePic,
      role: authRole.User,
      userType: user.type,
      age: user?.age,
      authType: user?.authType,
      createdAt: user?.createdAt,
      expertise: user?.expertise,
      education: user?.education,
      gender: user?.gender,
      interests: user?.interests,
      profession: user?.profession,
      qualification: user?.qualification,
      type: user?.type,
      verified: user?.verified,
      awardWinner: user?.awardWinner,
      coins: user?.coins,
      accountNumber: user?.accountNumber,
      description: user.description,
    };
  return user;
};
