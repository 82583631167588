/* eslint-disable prettier/prettier */
import { createReducer } from '@reduxjs/toolkit';
import { AcademyType } from '@crema/types/models/dashboards/Academy';
import { AnalyticsType } from '@crema/types/models/dashboards/Analytics';
import { CRMType } from '@crema/types/models/dashboards/CRM';
import { CryptoPropsType } from '@crema/types/models/dashboards/Crypto';
import { EcommerceType } from '@crema/types/models/dashboards/Ecommerce';
import { HealthCareType } from '@crema/types/models/dashboards/HealthCare';
import { MetricsType } from '@crema/types/models/dashboards/Metrics';
import { WidgetsType } from '@crema/types/models/dashboards/Widgets';
import {
  GetAcademyAction,
  GetAnalyticsAction,
  GetCrmAction,
  GetCryptoAction,
  GetEcomAction,
  GetHealthcareAction,
  GetMetricsAction,
  GetWidgetsAction,
  GetTeacherOwnCourses,
  CourseData,
  TeacherAnalytics,
  documentContent,
  UniqueID,
  SpecificCourseData,
  GetAllCategories,
  GetSubtitleFile,
  GetAssignmentData,
  SetSpecificReview,
  SetMessageUser,
  SetQuizDetails,
  SetAssignmentDetails,
  SetBonusDetails,
  SetCourseContent,
  SelectedChatUser,
} from './ActionTypes/Dashboard';

const initialState: {
  academyData: AcademyType | null;
  ecommerceData: EcommerceType | null;
  healthCare: HealthCareType | null;
  analyticsData: AnalyticsType | null;
  crmData: CRMType | null;
  cryptoData: CryptoPropsType | null;
  metricsData: MetricsType | null;
  widgetsData: WidgetsType | null;
  teacherCourses: any | null;
  courseData: any | null;
  teacheranalytics: any | null;
  documentContent: any | null;
  unique_id: string | null;
  currentCourse: any | null;
  allCategories: any | [];
  subtitleFile: any | object;
  assignmentData: any | object;
  specificReview: any | object;
  messageUser: any | object;
  quizDetail: any | object;
  assignmentDetail: any | object;
  bonusDetail: any | object;
  courseContent: any | object;
  selectedChatUser: any | null;
} = {
  analyticsData: null,
  ecommerceData: null,
  crmData: null,
  cryptoData: null,
  metricsData: null,
  widgetsData: null,
  healthCare: null,
  academyData: null,
  teacherCourses: null,
  courseData: null,
  teacheranalytics: null,
  documentContent: null,
  unique_id: '',
  currentCourse: null,
  allCategories: [],
  subtitleFile: {},
  assignmentData: null,
  specificReview: null,
  messageUser: null,
  quizDetail: null,
  assignmentDetail: null,
  bonusDetail: null,
  courseContent: null,
  selectedChatUser: null,
};

const dashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GetAnalyticsAction, (state, action) => {
      state.analyticsData = action.payload;
    })
    .addCase(GetEcomAction, (state, action) => {
      state.ecommerceData = action.payload;
    })
    .addCase(GetAcademyAction, (state, action) => {
      state.academyData = action.payload;
    })
    .addCase(GetCrmAction, (state, action) => {
      state.crmData = action.payload;
    })
    .addCase(GetCryptoAction, (state, action) => {
      state.cryptoData = action.payload;
    })
    .addCase(GetMetricsAction, (state, action) => {
      state.metricsData = action.payload;
    })
    .addCase(GetWidgetsAction, (state, action) => {
      state.widgetsData = action.payload;
    })
    .addCase(GetHealthcareAction, (state, action) => {
      state.healthCare = action.payload;
    })
    .addCase(GetTeacherOwnCourses, (state, action) => {
      state.teacherCourses = action.payload;
    })
    .addCase(CourseData, (state, action) => {
      state.courseData = action.payload;
    })
    .addCase(TeacherAnalytics, (state: any, action: any) => {
      state.teacheranalytics = action.payload;
    })
    .addCase(documentContent, (state: any, action: any) => {
      state.documentContent = action.payload;
    })
    .addCase(UniqueID, (state: any, action: any) => {
      state.unique_id = action.payload;
    })
    .addCase(SpecificCourseData, (state, action) => {
      state.currentCourse = action.payload;
    })
    .addCase(GetAllCategories, (state: any, action: any) => {
      state.allCategories = action.payload;
    })
    .addCase(GetSubtitleFile, (state: any, action: any) => {
      state.subtitleFile = action.payload;
    })
    .addCase(GetAssignmentData, (state: any, action: any) => {
      state.assignmentData = action.payload;
    })
    .addCase(SetSpecificReview, (state: any, action: any) => {
      state.specificReview = action.payload;
    })
    .addCase(SetMessageUser, (state: any, action: any) => {
      state.messageUser = action.payload;
    })
    .addCase(SetQuizDetails, (state: any, action: any) => {
      state.quizDetail = action.payload;
    })
    .addCase(SetAssignmentDetails, (state: any, action: any) => {
      state.assignmentDetail = action.payload;
    })
    .addCase(SetBonusDetails, (state: any, action: any) => {
      state.bonusDetail = action.payload;
    })
    .addCase(SetCourseContent, (state: any, action: any) => {
      state.courseContent = action.payload;
    })
    .addCase(SelectedChatUser, (state: any, action: any) => {
      state.selectedChatUser = action.payload;
    });
});

export default dashboardReducer;
