// export const baseUrl = 'http://localhost:3100/api/v1/';

// http url
// export const baseUrl = 'http://51.112.28.13:3100/api/v1/';
// export const socketUrl = 'http://51.112.28.13:3100';

// http secure (https) url

export const baseUrl = 'https://api.nmoacademy.com/api/v1/';
export const socketUrl = 'https://api.nmoacademy.com';

//Hanan URL
// export const baseUrl = 'http://192.168.100.75:3100/api/v1';
// export const socketUrl = 'http://192.168.100.75:3100';

// http://51.112.28.13:3200/
