import React, {Suspense} from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import AppLoader from '@crema/components/AppLoader';
const Courses = React.lazy(() => import('modules/dashboards/Courses'));
const CreateCourse = React.lazy(
  () => import('modules/dashboards/CreateCourse'),
);
const ManageCourse = React.lazy(
  () => import('modules/dashboards/ManageCourse'),
);
const CreateQuiz = React.lazy(
  () => import('modules/dashboards/ManageCourse/Quiz/CreateQuiz/index'),
);

const CreateAssignment = React.lazy(
  () => import('modules/dashboards/ManageCourse/Assignment/CreateAssignment'),
);

const QuizDetails = React.lazy(
  () => import('modules/dashboards/ManageCourse/Quiz/QuizDetails'),
);

const QuizSubmission = React.lazy(
  () => import('modules/dashboards/ManageCourse/Quiz/QuizSubmission'),
);

const AssignmentSubmission = React.lazy(
  () => import('modules/dashboards/ManageCourse/Assignment/ViewSubmissions'),
);

const ViewAssignmentDetails = React.lazy(
  () => import('modules/dashboards/ManageCourse/Assignment/AssignmentDetails'),
);

const CreateBonusContent = React.lazy(
  () => import('../../../modules/dashboards/ManageCourse/Bonus/CreateBonus'),
);

const FinancialStats = React.lazy(
  () => import('../../../modules/dashboards/Finance'),
);

export const dashBoardConfigs = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: '/courses',
    element: (
      <Suspense fallback={<AppLoader />}>
        <Courses />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: '/course/create',
    element: (
      <Suspense fallback={<AppLoader />}>
        <CreateCourse />
      </Suspense>
    ),
  },
  // {
  //   permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
  //   path: '/course/update/:id',
  //   // element: <Navigate to='/course/create' />,
  //   element: <CreateCourse />,
  // },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: ['/course/:id', '/course/:id/:section', '/course/:id/:section/:id'],
    element: (
      <Suspense fallback={<AppLoader />}>
        <ManageCourse />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: ['/course/:id/quiz/create'],
    element: (
      <Suspense fallback={<AppLoader />}>
        <CreateQuiz />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: '/course/:id/quiz/update/:quizID',
    element: (
      <Suspense fallback={<AppLoader />}>
        <CreateQuiz />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: ['/course/:id/quiz/:id'],
    element: (
      <Suspense fallback={<AppLoader />}>
        <QuizDetails />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: ['/course/:id/submission/:id'],
    element: (
      <Suspense fallback={<AppLoader />}>
        <QuizSubmission />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: ['/course/:id/viewsubmission/:submissionID'],
    element: (
      <Suspense fallback={<AppLoader />}>
        <AssignmentSubmission />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: ['/course/:id/assignment/:assignmentID'],
    element: (
      <Suspense fallback={<AppLoader />}>
        <ViewAssignmentDetails />
      </Suspense>
    ),
  },
  // {
  //   permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
  //   path: ['/dashboard/course/:id/quiz/'],
  //   element: <Quiz />,
  // },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: ['/course/:id/assignment/create'],
    element: (
      <Suspense fallback={<AppLoader />}>
        <CreateAssignment />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: '/course/:id/update/:assignmnentID',
    element: (
      <Suspense fallback={<AppLoader />}>
        <CreateAssignment />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: ['/course/:id/bonus/create'],
    element: (
      <Suspense fallback={<AppLoader />}>
        <CreateBonusContent />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Teacher],
    path: ['/finance'],
    element: (
      <Suspense fallback={<AppLoader />}>
        <FinancialStats />
      </Suspense>
    ),
  },
];
